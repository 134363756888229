<template>
  <div class="d-flex text-center">
    <v-card class="pt-6" flat rounded="lg" width="100%">
      <v-card-text>
        <v-avatar size="88" class="mb-6">
          <img v-if="!selectedPatient.photoURL" src="@/assets/avatar.png" />
          <img v-else :src="selectedPatient.photoURL" />
        </v-avatar>
        <h3 class="headline mb-2">
          {{ selectedPatient.fname }} {{ selectedPatient.lname }}
        </h3>
        <div class="blue--text mb-2">
          {{ selectedPatient.email }}
        </div>
        <div
          @click="sendMessage()"
          class="blue--text subheadingfont-weight-bold"
          style="cursor: pointer;"
        >
          {{ selectedPatient.phoneNumber }}
          <v-btn
            @click="sendMessage()"
            v-if="
              selectedPatient.phoneNumber &&
                selectedPatient.phoneNumber !== 'N/A'
            "
            color="primary"
            x-small
            icon
          >
            <v-icon>mdi-message-arrow-right-outline</v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="text-left">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="12"
            v-if="
              !selectedPatient.medicalcard.birthdate &&
                !selectedPatient.medicalcard.age &&
                !selectedPatient.medicalcard.height &&
                !selectedPatient.medicalcard.weight &&
                !selectedPatient.medicalcard.gender
            "
          >
            <div class="font-weight-bold">
              No medical card information
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <div>
              <span class="font-weight-bold">Birthdate:</span>
              {{ formatBirthDate(selectedPatient.medicalcard.birthdate) }}
            </div>
          </v-col>
          <v-col v-if="selectedPatient.medicalcard.age" cols="12" sm="6" md="6">
            <div>
              <span class="font-weight-bold">Age:</span>
              {{ selectedPatient.medicalcard.age }}
            </div>
          </v-col>
          <v-col
            v-if="selectedPatient.medicalcard.gender"
            cols="12"
            sm="6"
            md="6"
          >
            <div>
              <span class="font-weight-bold">Gender:</span>
              {{ selectedPatient.medicalcard.gender }}
            </div>
          </v-col>
          <v-col
            v-if="selectedPatient.medicalcard.height"
            cols="12"
            sm="6"
            md="6"
          >
            <div>
              <span class="font-weight-bold">Height:</span>
              {{ selectedPatient.medicalcard.height }} inches
            </div>
          </v-col>
          <v-col
            v-if="selectedPatient.medicalcard.weight"
            cols="12"
            sm="6"
            md="6"
          >
            <div>
              <span class="font-weight-bold">Weight:</span>
              {{ selectedPatient.medicalcard.weight }} pounds
            </div>
          </v-col>
          <v-col
            v-if="selectedPatient.medicalcard.address"
            cols="12"
            sm="12"
            md="12"
          >
            <div>
              <span class="font-weight-bold">Address:</span>
              {{ selectedPatient.medicalcard.address }}
            </div>
          </v-col>
          <v-col
            v-if="selectedPatient.medicalcard.pharmacy"
            cols="12"
            sm="12"
            md="12"
          >
            <div>
              <span class="font-weight-bold">Pharmacy:</span>
              {{ selectedPatient.medicalcard.pharmacy }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="sendMessageDialog" max-width="500px">
      <SendTextMessageDialog
        :user="selectedMessage"
        @closeSendMessage="closeMessageDialog"
        :key="selectedMessage.userId"
      />
    </v-dialog>
  </div>
</template>

<script>
import * as moment from 'moment'
import SendTextMessageDialog from '@/components/dialogs/SendTextMessageDialog'
export default {
  name: 'medicalcardDialog',
  props: ['selectedPatient'],
  components: {
    SendTextMessageDialog
  },
  data() {
    return {
      sendMessageDialog: false,
      selectedMessage: {}
    }
  },

  methods: {
    sendMessage() {
      this.selectedMessage = {
        phoneNumber: this.selectedPatient.phoneNumber,
        userId: this.selectedPatient.id
      }
      this.sendMessageDialog = true
    },
    closeMessageDialog() {
      this.sendMessageDialog = false
    },
    formatBirthDate(date) {
      date = this.selectedPatient.medicalcard.birthdate
      return moment(date.toDate()).format('MMMM DD, YYYY')
    }
  }
}
</script>
