<template>
  <v-card v-if="patient && patient.medicalcard">
    <v-toolbar dark color="primary">
      <v-toolbar-title>{{ patient.fname }} {{ patient.lname }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="close()">
          Close
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-card-text>
      <v-card-text>
        <v-row>
          <!-- <v-col cols="12" md="3">
          
          </v-col> -->
          <v-col cols="12" md="9">
            <v-row class="align-center">
              <v-col cols="12" md="2" class="d-flex justify-center">
                <v-avatar
                  :width="$mq === 'mobile' ? '100px' : '100%'"
                  :height="$mq === 'mobile' ? '100px' : '100%'"
                  class="mb-6"
                >
                  <img v-if="!patient.photoURL" src="@/assets/avatar.png" />
                  <img v-else :src="patient.photoURL" />
                </v-avatar>
              </v-col>

              <v-col cols="12" md="10">
                <v-chip
                  v-if="computedDateFormattedMomentjs"
                  color="primary"
                  outlined
                  label
                  :class="$mq === 'mobile' ? 'mb-2' : 'mr-4 mb-2'"
                  :style="$mq === 'mobile' ? 'width: 100%;' : 'width: 100%;'"
                >
                  <span class="mr-1 font-weight-bold">Birthdate: </span>
                  {{ computedDateFormattedMomentjs }}
                </v-chip>

                <v-chip
                  v-if="patient.medicalcard.age"
                  color="primary"
                  outlined
                  label
                  :class="$mq === 'mobile' ? 'mb-2' : 'mr-4 mb-2'"
                  :style="$mq === 'mobile' ? 'width: 100%;' : 'width: 100%;'"
                >
                  <span class="mr-1 font-weight-bold">Age:</span>
                  {{ patient.medicalcard.age }}
                </v-chip>

                <v-chip
                  v-if="patient.medicalcard.gender"
                  color="primary"
                  outlined
                  label
                  :class="$mq === 'mobile' ? 'mb-2' : 'mr-4 mb-2'"
                  :style="$mq === 'mobile' ? 'width: 100%;' : 'width: 100%;'"
                >
                  <span class="mr-1 font-weight-bold">Gender:</span>
                  {{ patient.medicalcard.gender }}
                </v-chip>

                <v-chip
                  v-if="patient.medicalcard.height"
                  color="primary"
                  outlined
                  label
                  :class="$mq === 'mobile' ? 'mb-2' : 'mr-4 mb-2'"
                  :style="$mq === 'mobile' ? 'width: 100%;' : 'width: 100%;'"
                >
                  <span class="mr-1 font-weight-bold">Height:</span>
                  {{ patient.medicalcard.height }} inches
                </v-chip>

                <v-chip
                  v-if="patient.medicalcard.weight"
                  color="primary"
                  outlined
                  label
                  :class="$mq === 'mobile' ? 'mb-2' : 'mr-4 mb-2'"
                  :style="$mq === 'mobile' ? 'width: 100%;' : 'width: 100%;'"
                >
                  <span class="mr-1 font-weight-bold">Weight:</span>
                  {{ patient.medicalcard.weight }} pounds
                </v-chip>
              </v-col>
              <v-spacer></v-spacer>
              <div v-if="$mq === 'mobile'" style="width: 100%;">
                <div v-if="patient.lastUpdated && patient.isExamComplete">
                  <v-chip
                    :style="$mq === 'mobile' ? 'width: 100%;' : ''"
                    color="green"
                    class="white--text"
                    label
                    >Updated: {{ patient.lastUpdated }}</v-chip
                  >
                </div>
                <div v-else>
                  <v-chip
                    :style="$mq === 'mobile' ? 'width: 100%;' : ''"
                    color="red"
                    class="white--text"
                    label
                    >Exam Not Completed</v-chip
                  >
                </div>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <template>
        <v-tabs
          id="settingsTabs"
          background-color="transparent"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          center-active
        >
          <v-tab ripple>Exam</v-tab>
          <v-tab ripple>Notes</v-tab>
          <v-tab ripple>Past Appointments</v-tab>
          <v-tab-item>
            <Exam
              :patientId="patient.patientId"
              :uniqueName="patient.id"
              :practiceId="patient.practiceId"
              class="mt-4"
            />
          </v-tab-item>
          <v-tab-item>
            <v-card rounded="lg">
              <CallNotes
                :patientId="patient.patientId"
                :uniqueName="patient.id"
                :practiceId="patient.practiceId"
                class="mt-4"
              />
            </v-card>
            <PastNotes
              :patientId="patient.patientId"
              :uniqueName="patient.id"
              :practiceId="patient.practiceId"
              class="mt-4"
            />
          </v-tab-item>
          <v-tab-item>
            <PastAppointments
              :patientId="patient.patientId"
              :uniqueName="patient.id"
            />
          </v-tab-item>

          <v-spacer v-if="$mq !== 'mobile'"></v-spacer>
          <div
            v-if="
              patient.lastUpdated && patient.isExamComplete && $mq !== 'mobile'
            "
          >
            <v-chip color="green" class="white--text" label
              >Updated: {{ patient.lastUpdated }}</v-chip
            >
          </div>
          <div
            v-if="
              !patient.lastUpdated &&
                !patient.isExamComplete &&
                $mq !== 'mobile'
            "
          >
            <v-chip color="red" class="white--text" label
              >Exam Not Completed</v-chip
            >
          </div>
        </v-tabs>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import * as moment from 'moment'
import CallNotes from '@/components/room/CallNotes'
import PastNotes from '@/components/room/PastNotes'
import PastAppointments from '@/components/room/PastAppointments'
import Exam from '@/components/room/Exam'

export default {
  name: 'ViewHistoryDialog',
  components: {
    Exam,
    CallNotes,
    PastNotes,
    PastAppointments
  },
  props: ['patient'],

  methods: {
    formatBirthDate(date) {
      return moment(date.toDate()).format('MMMM DD, YYYY')
    },
    close() {
      this.$emit('closeViewHistory')
    }
  },
  computed: {
    computedDateFormattedMomentjs: {
      get() {
        return this.patient.medicalcard.birthdate
          ? moment(this.patient.medicalcard.birthdate.toDate()).format(
              'MMMM DD, YYYY'
            )
          : ''
      },
      set() {}
    }
  }
}
</script>
