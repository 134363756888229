<template>
  <v-card width="100%">
    <v-toolbar class="mb-4" dark color="primary">
      <v-btn icon dark @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>My Exam</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="close()">
          close
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-card-text class="d-flex text-center">
      <v-row>
        <v-col cols="5">
          <v-card>
            <v-card-text>
              <v-avatar size="88" class="mb-6">
                <img
                  v-if="!selectedPatient.photoURL"
                  src="@/assets/avatar.png"
                />
                <img v-else :src="selectedPatient.photoURL" />
              </v-avatar>
              <h3 class="headline mb-2">
                {{ selectedPatient.fname }} {{ selectedPatient.lname }}
              </h3>
              <div class="blue--text mb-2">
                {{ selectedPatient.email }}
              </div>
              <div class="blue--text subheadingfont-weight-bold">
                {{ selectedPatient.phoneNumber }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="text-left">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-if="
                    !selectedPatient.medicalcard.birthdate &&
                      !selectedPatient.medicalcard.age &&
                      !selectedPatient.medicalcard.height &&
                      !selectedPatient.medicalcard.weight &&
                      !selectedPatient.medicalcard.gender
                  "
                >
                  <div class="font-weight-bold">
                    No medical card information
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <div>
                    <span class="font-weight-bold">Birthdate:</span>
                    {{ formatBirthDate(selectedPatient.medicalcard.birthdate) }}
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPatient.medicalcard.age"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <div>
                    <span class="font-weight-bold">Age:</span>
                    {{ selectedPatient.medicalcard.age }}
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPatient.medicalcard.gender"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <div>
                    <span class="font-weight-bold">Gender:</span>
                    {{ selectedPatient.medicalcard.gender }}
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPatient.medicalcard.height"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <div>
                    <span class="font-weight-bold">Height:</span>
                    {{ selectedPatient.medicalcard.height }} inches
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPatient.medicalcard.weight"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <div>
                    <span class="font-weight-bold">Weight:</span>
                    {{ selectedPatient.medicalcard.weight }} pounds
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPatient.medicalcard.address"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <div>
                    <span class="font-weight-bold">Address:</span>
                    {{ selectedPatient.medicalcard.address }}
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPatient.medicalcard.pharmacy"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <div>
                    <span class="font-weight-bold">Pharmacy:</span>
                    {{ selectedPatient.medicalcard.pharmacy }}
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row v-if="duplicate">
                <v-col cols="12">
                  Existing patient with the same first name, last name, and
                  birthdate found.
                </v-col>
                <v-col cols="12" v-for="item in pastPatients" :key="item.id">
                  {{ item.fname }} {{ item.lname }} -
                  {{ formatBirthDate(item.medicalcard.birthdate) }} <br />
                  Created - {{ formatCreated(item.createdAt) }}

                  <v-btn
                    style="float: right;"
                    @click="viewPastPatient(item)"
                    small
                    text
                    color="primary"
                    >View</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="2"
          v-if="selectedPastPatient"
          class="d-flex flex-column align-center justify-center"
        >
          <v-btn
            color="red"
            class="white--text"
            @click="isSure = true"
            :disabled="isSure"
          >
            <v-icon left>mdi-arrow-left-thick</v-icon>
            merge
          </v-btn>
          <!-- <div v-if="isSure">
            <div class="mt-2 mb-2">Are you sure?</div>
            <v-btn color="green" @click="confirmMerge()" dark>Yes</v-btn>
            <v-btn class="ml-2" @click="isSure = false" color="red" dark
              >No</v-btn
            >
          </div> -->
        </v-col>
        <v-col cols="5" v-if="selectedPastPatient">
          <v-card>
            <v-card-text>
              <v-avatar size="88" class="mb-6">
                <img
                  v-if="!selectedPastPatient.photoURL"
                  src="@/assets/avatar.png"
                />
                <img v-else :src="selectedPastPatient.photoURL" />
              </v-avatar>
              <h3 class="headline mb-2">
                {{ selectedPastPatient.fname }}
                {{ selectedPastPatient.lname }}
              </h3>
              <div class="blue--text mb-2">
                {{ selectedPastPatient.email }}
              </div>
              <div class="blue--text subheadingfont-weight-bold">
                {{ selectedPastPatient.phoneNumber }}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="text-left">
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="12"
                  v-if="
                    !selectedPastPatient.medicalcard.birthdate &&
                      !selectedPastPatient.medicalcard.age &&
                      !selectedPastPatient.medicalcard.height &&
                      !selectedPastPatient.medicalcard.weight &&
                      !selectedPastPatient.medicalcard.gender
                  "
                >
                  <div class="font-weight-bold">
                    No medical card information
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <div>
                    <span class="font-weight-bold">Birthdate:</span>
                    {{
                      formatBirthDate(selectedPastPatient.medicalcard.birthdate)
                    }}
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPastPatient.medicalcard.age"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <div>
                    <span class="font-weight-bold">Age:</span>
                    {{ selectedPastPatient.medicalcard.age }}
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPastPatient.medicalcard.gender"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <div>
                    <span class="font-weight-bold">Gender:</span>
                    {{ selectedPastPatient.medicalcard.gender }}
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPastPatient.medicalcard.height"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <div>
                    <span class="font-weight-bold">Height:</span>
                    {{ selectedPastPatient.medicalcard.height }} inches
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPastPatient.medicalcard.weight"
                  cols="12"
                  sm="6"
                  md="6"
                >
                  <div>
                    <span class="font-weight-bold">Weight:</span>
                    {{ selectedPastPatient.medicalcard.weight }} pounds
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPastPatient.medicalcard.address"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <div>
                    <span class="font-weight-bold">Address:</span>
                    {{ selectedPastPatient.medicalcard.address }}
                  </div>
                </v-col>
                <v-col
                  v-if="selectedPastPatient.medicalcard.pharmacy"
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <div>
                    <span class="font-weight-bold">Pharmacy:</span>
                    {{ selectedPastPatient.medicalcard.pharmacy }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="isSure" max-width="400px">
      <v-card>
        <v-card-title
          >HIPPA Notice
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="isSure = false">Cancel</v-btn>
        </v-card-title>
        <v-card-text>
          The following profiles have the same First name, Last name, and Date
          of Birth. If you are clinically confident based on comparing profile
          pictures and your prior experience with the patient, we can merge the
          patient profiles. Merged patient profiles cannot be unmerged. Do you
          want to merge patient profiles?
          <v-btn
            class="mt-4"
            block
            text
            @click="confirmMerge()"
            color="primary"
            :loading="overlay"
            :disabled="overlay"
          >
            Merge Patient
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay z-index="99999" :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import * as fb from '@/firebase'
import * as moment from 'moment'
export default {
  name: 'DuplicatePatientDialog',
  props: ['selectedPatient'],
  data() {
    return {
      patientsCollection: [],
      pastPatients: [],
      selectedPastPatient: null,
      selectedPastPatientId: null,
      duplicate: false,
      isSure: false,
      practiceId: '',
      overlay: false
    }
  },
  watch: {
    patientsCollection: function() {
      if (this.selectedPatient.isDuplicate) {
        this.duplicate = true

        this.findDuplicates(
          this.selectedPatient.fname,
          this.selectedPatient.lname,
          this.selectedPatient.medicalcard.birthdate
        )
      }
    }
  },

  async mounted() {
    await fb.proxiesCollection
      .doc(fb.auth.currentUser.uid)
      .get()
      .then(user => {
        this.practiceId = user.data().practiceId
      })
    if (this.selectedPatient.isDuplicate) {
      this.duplicate = true
      this.$bind('patientsCollection', fb.patientsCollection).then(() => {
        this.findDuplicates(
          this.selectedPatient.fname,
          this.selectedPatient.lname,
          this.selectedPatient.medicalcard.birthdate
        )
      })
    }
  },

  methods: {
    formatBirthDate(date) {
      // date = this.selectedPatient.medicalcard.birthdate
      return moment(date.toDate()).format('MMMM DD, YYYY')
    },
    formatCreated(date) {
      // date = this.selectedPatient.medicalcard.birthdate
      return moment(date.toDate()).format('MMMM DD, YYYY hh:mm')
    },
    findDuplicates(fname, lname, birthdate) {
      this.pastPatients = this.patientsCollection.filter(
        item =>
          item.fname.toLowerCase() == fname.toLowerCase() &&
          item.lname.toLowerCase() == lname.toLowerCase() &&
          moment(item.medicalcard.birthdate.toDate()).isSame(
            moment(birthdate.toDate()),
            'day'
          ) &&
          item.email == 'anonymous'
        //&& item.id !== this.selectedPatient.patientId
      )

      this.pastPatients.sort((a, b) =>
        moment(a.createdAt.toDate()).isBefore(moment(b.createdAt.toDate()))
          ? 1
          : -1
      )
    },
    viewPastPatient(item) {
      this.selectedPastPatient = null
      this.selectedPastPatientId = null
      this.selectedPastPatient = { ...item }
      this.selectedPastPatientId = item.id
    },
    close() {
      this.$emit('closeViewPastDialog')
    },
    async confirmMerge() {
      this.overlay = true
      var appointments = []
      const mergeAnonymousDocs = fb.functions.httpsCallable(
        'mergeAnonymousDocs'
      )
      const deleteAnonUser = fb.functions.httpsCallable('deleteAnonUser')

      let selectedPastPatientQuery = fb.db
        .collection('Patients')
        .doc(this.selectedPastPatientId)
      let selectedPatientQuery = fb.db
        .collection('Patients')
        .doc(this.selectedPatient.patientId)

      let photoURL =
        'https://firebasestorage.googleapis.com/v0/b/vpexam-hhvi-dhp.appspot.com/o/avatar.png?alt=media&token=44b5812d-a7ad-4f80-ac34-5386c88bd4a7'
      let newEmail = 'anonymous'
      let phoneNumber = 'N/A'

      await selectedPastPatientQuery
        .get()
        .then(doc => {
          const oldPatientData = doc.data()
          if (oldPatientData) {
            if (
              oldPatientData.photoURL !==
              'https://firebasestorage.googleapis.com/v0/b/vpexam-hhvi-dhp.appspot.com/o/avatar.png?alt=media&token=44b5812d-a7ad-4f80-ac34-5386c88bd4a7'
            ) {
              photoURL = oldPatientData.photoURL
            }
            if (oldPatientData.email !== 'anonymous') {
              newEmail = oldPatientData.email
            }
            if (
              oldPatientData.phoneNumber !== 'N/A' &&
              oldPatientData.phoneNumber !== '' &&
              oldPatientData.phoneNumber !== null
            ) {
              phoneNumber = oldPatientData.phoneNumber
            }
          }
        })
        .catch(err => console.log(err))

      await selectedPatientQuery
        .get()
        .then(doc => {
          const patientData = doc.data()
          if (patientData) {
            if (
              patientData.photoURL !==
              'https://firebasestorage.googleapis.com/v0/b/vpexam-hhvi-dhp.appspot.com/o/avatar.png?alt=media&token=44b5812d-a7ad-4f80-ac34-5386c88bd4a7'
            ) {
              photoURL = patientData.photoURL
            }
            if (patientData.email !== 'anonymous') {
              newEmail = patientData.email
            }
            if (
              patientData.phoneNumber !== 'N/A' &&
              patientData.phoneNumber !== '' &&
              patientData.phoneNumber !== null
            ) {
              phoneNumber = patientData.phoneNumber
            }
          }
        })
        .catch(err => console.log(err))

      await selectedPatientQuery
        .set(
          {
            ...this.selectedPastPatient,
            email: newEmail,
            photoURL: photoURL,
            phoneNumber: phoneNumber
          },
          {
            merge: true
          }
        )
        .catch(err => console.log(err))

      fb.patientsCollection
        .doc(this.selectedPastPatientId)
        .collection('Exam_Notes')
        .get()
        .then(snapshot => {
          for (const note of snapshot.docs) {
            fb.patientsCollection
              .doc(this.selectedPatient.patientId)
              .collection('Exam_Notes')
              .doc(note.id)
              .set({ ...note.data() })
          }
        })

      await fb.practicesCollection
        .doc(this.practiceId)
        .collection('Appointments')
        .where('patientId', '==', this.selectedPastPatientId)
        .get()
        .then(snapshot => {
          for (const app of snapshot.docs) {
            const data = app.data()
            const startDate = moment(data.startTime.toDate())
            if (startDate.isSame(moment(), 'day')) {
              appointments.push({
                id: app.id,
                time: moment(app.data().startTime.toDate()).format('LLLL')
              })
            }
          }
        })

      if (appointments.length > 0) {
        try {
          await mergeAnonymousDocs({
            appointments: appointments,
            selectedPastPatientId: this.selectedPastPatientId,
            selectedPatientId: this.selectedPatient.patientId,
            practiceId: this.practiceId,
            currentAppointment: this.selectedPatient.id
          })
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          await deleteAnonUser({
            patientId: this.selectedPastPatientId
          })
        } catch (error) {
          console.log(error)
        }
      }

      this.overlay = false
      this.isSure = false
      this.close()
    }
  }
}
</script>
