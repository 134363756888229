<template>
  <div class="patients">
    <v-container>
      <h1>Waiting Room</h1>
      <br />
      <v-card rounded="lg">
        <v-data-table
          :headers="headers"
          :items="patientsFiltered"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          :search="search"
          hide-default-footer
          @page-count="pageCount = $event"
          rounded="lg"
        >
          <template v-slot:item="{ item }">
            <tr :style="item.isDuplicate ? 'background: #d115164a;' : ''">
              <td>
                <v-badge
                  bordered
                  top
                  dot
                  color="red"
                  offset-x="10"
                  offset-y="10"
                  v-if="item.isDuplicate"
                >
                  <v-avatar
                    @click="openDuplicateCard(item.data, item)"
                    size="35"
                    style="cursor: pointer;"
                  >
                    <img v-if="!item.data.photoURL" src="@/assets/avatar.png" />
                    <img v-else :src="item.data.photoURL" />
                  </v-avatar>
                </v-badge>
                <v-avatar
                  v-else
                  @click="openCard(item.data, item)"
                  size="35"
                  style="cursor: pointer;"
                >
                  <img v-if="!item.data.photoURL" src="@/assets/avatar.png" />
                  <img v-else :src="item.data.photoURL" />
                </v-avatar>

                <v-icon
                  v-if="item.isExamComplete"
                  color="green"
                  class="ml-2"
                  :small="$mq === 'tabletlg' || $mq === 'tablet'"
                  @click="viewHistory(item)"
                >
                  assignment
                </v-icon>
              </td>
              <td @click="viewHistory(item)" style="cursor: pointer;">
                {{ item.data.fname }}
              </td>
              <td @click="viewHistory(item)" style="cursor: pointer;">
                {{ item.data.lname }}
              </td>
              <td @click="viewHistory(item)" style="cursor: pointer;">
                {{ item.data.email }}
              </td>
              <td style="cursor: pointer;">
                {{ item.data.phoneNumber ? item.data.phoneNumber : 'N/A' }}
                <v-btn
                  @click="sendMessage(item)"
                  v-if="
                    item.data.phoneNumber && item.data.phoneNumber !== 'N/A'
                  "
                  color="primary"
                  x-small
                  icon
                >
                  <v-icon>mdi-message-arrow-right-outline</v-icon>
                </v-btn>
              </td>
              <td @click="viewHistory(item)" style="cursor: pointer;">
                <v-icon color="green" v-if="item.isWaiting">wifi</v-icon>
                <v-icon v-if="!item.isWaiting">wifi_off</v-icon>
              </td>
              <td>
                <v-btn
                  v-if="item.isDuplicate"
                  color="primary"
                  class="mr-2"
                  elevation="0"
                  :disabled="!item.isDuplicate"
                  :small="$mq === 'tablet'"
                  @click="openDuplicateCard(item.data, item)"
                  >Merge</v-btn
                >
                <v-btn
                  color="primary"
                  outlined
                  class="mr-2"
                  :disabled="!item.isWaiting"
                  :small="$mq === 'tablet'"
                  @click="startCall(item)"
                >
                  Start Call
                </v-btn>
                <v-btn
                  icon
                  color="red"
                  :small="$mq === 'tablet'"
                  :loading="item.loading"
                  @click.once="deleteAppointment(item)"
                >
                  <v-icon>
                    mdi-trash-can
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
        <div class="d-flex pt-2">
          <v-pagination
            class="justify-left ml-3 mb-6"
            v-model="page"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </div>
      </v-card>

      <v-dialog v-model="viewCard" max-width="500px">
        <MedicalCardDialog :selectedPatient="selectedPatient" />
      </v-dialog>
      <v-dialog
        v-model="viewDuplicateCard"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        height="100%"
      >
        <DuplicatePatientDialog
          @closeViewPastDialog="viewDuplicateCard = false"
          :selectedPatient="selectedPatient"
          :key="selectedPatient.patientId"
        />
      </v-dialog>
      <v-dialog
        v-if="viewHistoryDialog"
        v-model="viewHistoryDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <ViewHistoryDialog
          :patient="selectedPatient"
          @closeViewHistory="viewHistoryDialog = false"
          :key="selectedPatient.patientId"
        />
      </v-dialog>
      <v-dialog v-model="sendMessageDialog" max-width="500px">
        <SendTextMessageDialog
          :user="selectedMessage"
          @closeSendMessage="closeMessageDialog"
          :key="selectedMessage.userId"
        />
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import * as fb from '../firebase'
import * as moment from 'moment'
import ProxyLayout from '@/layouts/ProxyLayout'
import MedicalCardDialog from '../components/dialogs/MedicalCardDialog'
import DuplicatePatientDialog from '../components/dialogs/DuplicatePatientDialog'
import ViewHistoryDialog from '../components/dialogs/ViewHistoryDialog'
import SendTextMessageDialog from '@/components/dialogs/SendTextMessageDialog'
export default {
  name: 'DocWaitingRoom',
  props: ['user'],
  components: {
    MedicalCardDialog,
    ViewHistoryDialog,
    DuplicatePatientDialog,
    SendTextMessageDialog
  },
  data() {
    return {
      sendMessageDialog: false,

      loading: false,
      viewCard: false,
      viewDuplicateCard: false,
      viewHistoryDialog: false,
      search: '',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: 'View', value: 'mcard', sortable: false },
        {
          text: 'First Name',
          align: 'start',
          value: 'data.fname'
        },
        {
          text: 'Last Name',
          value: 'data.lname'
        },
        { text: 'Email', value: 'data.email' },
        { text: 'Phone Number', sortable: false, value: 'data.phoneNumber' },
        { text: 'Status', value: 'status', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ],
      patients: [],
      patientsFiltered: [],
      selectedMessage: {},
      selectedPatient: {
        fname: '',
        lname: '',
        email: '',
        phoneNumber: '',
        primaryDoctor: '',
        id: '',
        medicalcard: {
          age: '',
          birthdate: '',
          gender: '',
          height: '',
          weight: ''
        }
      },
      practice: {
        id: ''
      },
      appointments: [],
      appointmentsSorted: [],
      patientsCollection: [],
      currentUser: null
    }
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        if (this.user) {
          this.currentUser = this.user
          this.$emit(`update:layout`, ProxyLayout)
          this.$bind(
            'appointments',
            fb.db
              .collection('Practices')
              .doc(this.currentUser.practiceId)
              .collection('Appointments')
              .where('doctorId', '==', this.currentUser.id)
          )
        }
      }
    },
    appointments: {
      immediate: true,
      handler() {
        this.appointmentsSorted = []
        for (const app of this.appointments) {
          this.appointmentsSorted.push(app)
        }

        this.appointmentsSorted.sort((a, b) =>
          moment(a.created.toDate()).isBefore(moment(b.created.toDate()))
            ? 1
            : -1
        )
      }
    },
    appointmentsSorted: function() {
      this.patients = []
      for (const item of this.appointmentsSorted) {
        if (moment(item.startTime.toDate()).isSame(moment(), 'day')) {
          fb.patientsCollection
            .doc(item.patientId)
            .get()
            .then(snapshot => {
              const data = snapshot.data()

              var isDuplicate = this.findDuplicates(
                data.fname,
                data.lname,
                data.medicalcard.birthdate
              )

              if (
                !this.patients.some(
                  patient =>
                    patient.data.fname.toLowerCase() ===
                      data.fname.toLowerCase() &&
                    patient.data.lname.toLowerCase() ===
                      data.lname.toLowerCase() &&
                    moment(patient.data.medicalcard.birthdate.toDate()).isSame(
                      moment(data.medicalcard.birthdate.toDate()),
                      'day'
                    ) &&
                    moment(patient.data.createdAt.toDate()).isAfter(
                      moment(data.createdAt.toDate())
                    )
                )
              ) {
                this.patients.push({
                  data,
                  isWaiting: item.isWaiting,
                  url: item.url,
                  path: item.path,
                  id: item.id,
                  vitalStamp: item.vitalStamp,
                  patientId: snapshot.id,
                  isExamComplete: item.isExamComplete,
                  isDuplicate: isDuplicate,
                  loading: false
                })
              }
            })
        }
      }
    },
    patients: function() {
      this.patientsFiltered = this.patients.filter(
        (v, i, a) => a.findIndex(t => t.id === v.id) === i
      )
    }
  },
  async created() {
    await this.$bind('patientsCollection', fb.patientsCollection)
  },
  methods: {
    openCard(itemData, item) {
      this.selectedPatient = {
        medicalcard: {
          age: '',
          birthdate: '',
          gender: '',
          height: '',
          weight: ''
        },
        ...item,
        ...itemData,
        id: item.patientId
      }

      this.viewCard = true
    },
    openDuplicateCard(itemData, item) {
      this.selectedPatient = {
        medicalcard: {
          age: '',
          birthdate: '',
          gender: '',
          height: '',
          weight: ''
        },
        ...item,
        ...itemData
      }

      this.viewDuplicateCard = true
    },
    sendMessage(item) {
      this.selectedMessage = {
        phoneNumber: item.data.phoneNumber,
        userId: item.patientId
      }
      this.sendMessageDialog = true
    },
    closeMessageDialog() {
      this.sendMessageDialog = false
    },
    viewHistory(item) {
      let vitalStamp = null
      if (item.vitalStamp) {
        vitalStamp = moment(item.vitalStamp.toDate()).format(
          'MM/DD/YYYY HH:mm A'
        )
      }
      this.selectedPatient = {
        medicalcard: {
          age: '',
          birthdate: '',
          gender: '',
          height: '',
          weight: ''
        },
        id: item.id,
        patientId: item.patientId,
        practiceId: this.currentUser.practiceId,
        lastUpdated: vitalStamp,
        isExamComplete: item.isExamComplete,
        isDuplicate: item.isDuplicate,
        ...item.data
      }

      this.viewHistoryDialog = true
    },
    formatViDate(date) {
      date = this.selectedPatient.medicalcard.birthdate
      return moment(date).format('MM/DD/YYYY')
    },
    startCall(item) {
      this.$router.push(this.$route.query.redirect || '/room' + item.path)
    },
    async deleteAppointment(item) {
      item.loading = true
      const deleteAppointment = fb.functions.httpsCallable('deleteAppointment')

      try {
        await deleteAppointment({
          appointmentId: item.id,
          practiceId: this.currentUser.practiceId
        })
      } catch (error) {
        this.error = error.message
      }
      item.loading = false
    },
    findDuplicates(fname, lname, birthdate) {
      let isDuplicate = false
      var existingCount = this.patientsCollection.filter(
        item =>
          item.fname.toLowerCase() == fname.toLowerCase() &&
          item.lname.toLowerCase() == lname.toLowerCase() &&
          moment(item.medicalcard.birthdate.toDate()).isSame(
            moment(birthdate.toDate()),
            'day'
          )
      ).length
      if (existingCount > 1) {
        isDuplicate = true
      }
      return isDuplicate
    }
  }
}
</script>
